import React from 'react';

import {
  Switch, Route, Redirect,
} from 'react-router-dom';

import Login from 'modules/auth/containers/Login';
import ForgotPassword from 'modules/auth/containers/ForgotPassword';
import ResetPassword from 'modules/auth/containers/ResetPassword';

const portalUrl = {
  'https://core-deliverybox-develop.multisyscorp.io': 'https://deliverybox-develop.multisyscorp.io', // DEVELOP
  'https://core-stg.deliverybox.ph': 'https://stg.deliverybox.ph', // STAGING
  'https://core.deliverybox.ph': 'https://www.deliverybox.ph', // PROD
};

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path='/portal' component={() => {
        const {origin} = window.location;
        window.location.href = portalUrl[origin] || 'https://www.deliverybox.ph/';
        return null;
      }}/>
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  );
}

export default PublicRoutes;