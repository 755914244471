import React from 'react';

function Version() {
  return (
    <div className="w-full flex justify-center">
      <span className="text-body text-gray-400">v. 1.0.1</span>
    </div>
  );
}

export default Version;
