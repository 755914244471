import { useEffect } from 'react';

import { req, useApiGet } from 'react-reqq';
import Cookie from 'js-cookie';

import * as c from './constants';

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});

  useEffect(() => {
    const token = Cookie.get(c.TOKEN_ID);
    const type = Cookie.get('_userType');

    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
      type
    });
  }, []);

  return {
    isAppReady: typeof auth.isAuthenticated === 'boolean',
    isAuthenticated: auth.isAuthenticated,
    authType: auth.type,
  };
};

export const useProfile = () => {
  const profile = useApiGet(c.PROFILE_DATA, {});

  useEffect(() => {
    const profileData = JSON.parse(Cookie.get('_profileData'));
    req.set(c.PROFILE_DATA, profileData);
  }, []);

  return profile;
};
