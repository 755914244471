import React from 'react';

import DeliverBoxIcon from 'assets/images/deliverybox-icon.svg';
import './page-loader.css';

function PageLoader() {
  return (
    <main className="h-screen flex flex-col items-center justify-center">
      <img
        src={DeliverBoxIcon}
        alt="DeliveryBox"
        title="DeliveryBox"
        className="w-32 pulse"
      />

      <h1 className="sr-only">Loading</h1>
    </main>
  );
}

export default PageLoader;
