import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  FormWrapper,
} from 'msys-ds';
import * as actions from '../actions';
import * as c from '../constants';
import AuthenticatorImage from 'assets/images/key.png';
import SMSImage from 'assets/images/chat.png';
import EmailImage from 'assets/images/mail.png';
import { useApiLoading } from 'react-reqq';

const METHODS = [
  {
    src: AuthenticatorImage,
    label: 'APP Authenticator',
    label2: 'Google Authenticator',
    value: 'google',
    URL: '/login/authenticator',
    description: 'Enter a single-use code from your Google Authenticator.',
  },
  {
    src: SMSImage,
    label: 'SMS Authentication',
    label2: 'SMS',
    value: 'mobile',
    URL: '/login/auth-otp',

    description: 'Enter a single-use code from your registered mobile number.',
  },
  {
    src: EmailImage,
    label: 'Email Authentication',
    label2: 'Email',
    value: 'email',
    URL: '/login/auth-otp',
    description: 'Enter a single-use code from your registered email address.',
  },
];

const INIT_FORM = {
  otp: '',
};

function MFAModal({ onClose, state, setState }) {
  const [method, setMethod] = useState({});
  const history = useHistory();
  const isLoading = useApiLoading(c.VERIFY_OTP, 'post');

  const onMethodClick = async (row) => {
    setMethod(row);
    setState({ ...state, otp_via: row?.value });
  };

  const handleSubmit = () => {
    if (method?.value === 'google') {
      onClose();
      if (state.google_enabled)
          return history.push('/login/auth-otp');
      return history.push(method?.URL);
    }

    actions.verifyCredentials(
      {
        email: state?.email,
        password: state?.password,
        otp_via: method?.value,
      },
      () => {
        onClose();
        history.push(method?.URL);
      },
    );
  };

  return (
    <FormWrapper defaultValues={INIT_FORM} onSubmit={handleSubmit}>
      <div className="space-y-8 max-w-md mx-auto">
        <div>
          <div className="text-gray-800 text-xs">
            Multi-factor authentication is required to add additional layer of
            security when signing in to your account
          </div>
        </div>

        <div>
          {METHODS.map((item) => {
            const isSmsDisabled =  (state?.profile_type === 'profile_administrators' && item?.value === 'mobile') ? true :  (item?.value === 'mobile' && !state.mobile_verified) ? true : false

            if (isSmsDisabled) return null;
            return (
              <button
                className={cn(
                  'flex my-5 p-3 hover:bg-primary-muted focus:border-primary rounded-md cursor-pointer space-x-5 text-left border border-gray-500',
                  {
                    'bg-primary-muted border-primary':
                      method?.value === item?.value,
                  },
                )}
                onClick={() => onMethodClick(item)}
                // disabled={isPostLoading || isSmsDisabled}
                type="button"
                primary
              >
                <div className="grid grid-cols-6 gap-4  rounded-sm">
                  <div className="h-12 grid justify-items-center items-center">
                    <img src={item.src} alt="auth" className="h-auto mx-auto" />
                  </div>
                  <div className="grid grid-cols-subgrid gap-2 col-span-5 text-left">
                    <div className="font-semibold">{item.label}</div>
                    <div className="text-xs text-gray-800">
                      {item.description}
                    </div>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
        <div className="flex justify-center w-full">
          <Button
            solid
            primary
            type="submit"
            isLoading={isLoading}
            className="w-full rounded-sm mb-4"
          >
            Confirm
          </Button>
        </div>
      </div>
    </FormWrapper>
  );
}

MFAModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MFAModal;
