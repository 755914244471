import React, { createContext, useState } from 'react';

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import DocumentTitle from 'modules/common/components/DocumentTitle';

import LoginFormAdmin from './LoginFormAdmin';
import LoginFormSubscriber from './LoginFormSubscriber';
import Authenticator from '../../MFA/containers/Authenticator';
import OTP from 'modules/MFA/containers/OTP ';

export const MFAContext = createContext();

const INIT = {
  google_enabled: false,
  mobile_verified: false,
  profile_type: '',
  password: '',
  email: '',
  otp_via:''
};

function Login() {
  const match = useRouteMatch();
  const [state, setState] = useState(INIT);

  return (
    <>
      <MFAContext.Provider value={{ credential: [state, setState] }}>
        <DocumentTitle title="Login" />
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to={`${match.path}/subscriber`} />}
          />
          <Route
            path={`${match.path}/subscriber`}
            component={LoginFormSubscriber}
          />
          <Route
            path={`${match.path}/admin`} component={LoginFormAdmin}
          />
          <Route
            path={`${match.path}/authenticator`}
            component={Authenticator}
          />
          <Route path={`${match.path}/auth-otp`} render={ () => <OTP state={state} setState={setState} />} />
          <Route render={() => <Redirect to={`${match.path}/subscriber`} />} />
        </Switch>
      </MFAContext.Provider>
    </>
  );
}

Login.propTypes = {};

export default Login;
