import React, { useEffect, useState, useContext } from 'react'
import DeliveryBox from 'assets/images/deliverybox-logo.svg';
import GgoogleAuthenticator from 'assets/images/googleAuthenticator.png';
import MSAuthentiator from 'assets/images/MSAuthenticator.png';
import {
  useCreateModal,
  ModalBody,
} from 'msys-ds';
import { Link, useHistory } from 'react-router-dom';
import * as actions from '../actions';
import { MFAContext } from '../../auth/containers/Login';

import AuthenticatorConfirmModal from 'modules/MFA/modals/AuthenticatorConfirmModal';

const useQrCode = (email) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_END_POINT}/api/v1/show/qr/${email}`).then(
      async (raw) => {
        const svg = await raw.blob();
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        setState(url);
      },
    );
  }, [email]);
  return state;
}

function Authenticator() {
  const history = useHistory();
  // const credential = JSON.parse(sessionStorage.getItem('credential')) || {};
  const [secret, setSecret] = useState('');
  const createModal = useCreateModal('create-partner');
  const { credential } = useContext(MFAContext);
  const [state] = credential;
  const qrCodeUrl = useQrCode(state?.email);

  useEffect(() => {
    if (!state?.email) return history.push('/');
  }, []); // eslint-disable-line

  const onSuccess = () => {
    createModal({
      content: (close) => (
        <ModalBody
          onClose={close}
          title={<div className="font-semibold">Setup Complete</div>}
          md
        >
          <AuthenticatorConfirmModal onClose={close} />
        </ModalBody>
      ),
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const payload = {
      email: state?.email,
      password: state?.password,
      otp_via: 'google',
      secret: secret,
    };

    actions.verifyOTP(payload, () => {
      onSuccess();
    });

  };

  return (
    <main className="flex justify-center p-2 items-center">
      <div className="w-[780px] px-4 rounded-lg ">
        <div className="text-center">
          <div className="box-border mt-10 text-center">
            <img
              src={DeliveryBox}
              alt="DeliveryBox"
              title="DeliveryBox"
              className="h-20 mx-auto"
            />
          </div>
          <div>
            <h1 className="font-bold text-subtitle py-4">
              Enable Multi-Factor Authentication
            </h1>
          </div>
          <div>
            <p className="max-w-md mx-auto  ">
              Add an extra layer of security to your account by enabling
              Multi-Factor Authentication (MFA).
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 divide-y ">
          <div className="p-10">
            <div className="flex mb-2">
              <div className="w-8 font-bold">1</div>
              <div className="w-5/6 font-bold">Download authenticator app</div>
            </div>
            <div className="font-body flex font-light">
              <div className="w-8"></div>
              <div className="w-5/6">
                Install an authenticator app to your phone. You'll use this app
                to generate verification codes when logging in. Here are some
                options to install on your iOS/Android device:
              </div>
            </div>

            <div className="font-body flex font-light mt-5">
              <div className="w-8"></div>
              <div className="w-5/6">
                <div className="flex">
                  <button className="justify-items-center items-center mr-4 flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-xs text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <img
                      src={GgoogleAuthenticator}
                      className="mr-2"
                      alt="Google Authenticator icon"
                    />
                    <span>Google Authenticator</span>
                  </button>
                  <button className=" justify-items-center items-center mr-4 flex text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-xs text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <img
                      src={MSAuthentiator}
                      className="mr-2"
                      alt="Microsoft Authenticator icon"
                    />
                    <span>Microsoft Authenticator</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p-10">
            <div className="flex justify-items-center items-center">
              <div className="w-3/4">
                <div className="flex mb-2">
                  <div className="w-8 font-bold">2</div>
                  <div className="w-5/6 font-bold">
                    Scan QR Code with authenticator app
                  </div>
                </div>
                <div className="font-body flex font-light">
                  <div className="w-8"></div>
                  <div className="w-5/6">
                    Install an authenticator app to your phone. You'll use this
                    app to generate verification codes when logging in. Here are
                    some options to install on your iOS/Android device:
                  </div>
                </div>
              </div>
              <div className="w-1/4">
                <div className="box-border h-34 w-34 border border-gray-300">
                  <img
                    id="barcode"
                    src={qrCodeUrl}
                    alt=""
                    title="QR"
                    width="250"
                    height="250"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-10">
            <div className="flex">
              <div className="w-3/4">
                <div className="flex mb-2">
                  <div className="w-8 font-bold">3</div>
                  <div className="w-5/6 font-bold">Enter verification code</div>
                </div>
                <div className="font-body flex font-light">
                  <div className="w-8"></div>
                  <div className="w-5/6">
                    After scanning the QR code, your authenticator app will
                    generate a verification code. Enter this code below to
                    verify your setup.
                  </div>
                </div>
              </div>
              <div className="w-1/4">
                <form
                  class=" items-center max-w-sm mx-auto"
                  onSubmit={handleSubmit}
                >
                  <label>Code</label>
                  <div class="flex mt-2">
                    <div class="relative w-full">
                      <input
                        type="text"
                        id="simple-search"
                        class="bg-gray-100 border border-gray-300 text-gray-800 text-sm block w-full ps-10 p-2.5 rounded-l-sm"
                        placeholder="Search branch name..."
                        required
                        autoComplete="off"
                        value={secret}
                        onChange={(e) => {
                          setSecret(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      type="submit"
                      class="p-2.5 ms-2 text-sm font-medium bg-gray-100 border border-gray-400 rounded-l-sm"
                    >
                      Submit
                      <span class="sr-only">Search</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mx-auto my-10 font-bold">
          <Link to="/" className="text-primary">
            Remind Me Later
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Authenticator