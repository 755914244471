import React, { useContext } from 'react';

import { useApiLoading } from 'react-reqq';
import { Link } from 'react-router-dom';
import {
  Button,
  Field,
  FormWrapper,
  Input,
  InputPassword,
  useCreateModal,
  ModalBody,
} from 'msys-ds';

import DeliveryBox from 'assets/images/deliverybox-logo.svg';
import LayoutImage from '../components/LayoutImage';
import * as actions from '../../MFA/actions';
import * as c from '../../MFA/constants';
import MFAModal from '../../MFA/modals/MFAModal';

import { MFAContext } from './Login';

const INIT_FORM = {
  email: '',
  password: '',
};

const validationSchema = (yup) =>
  yup.object().shape({
    email: yup.string().required('Required').email('Invalid email'),
    password: yup.string().required('Required'),
  });

function LoginFormSubscriber() {
  const isLoading = useApiLoading(c.LOGIN, 'post');
  const createModal = useCreateModal('create-partner');
  const { credential } = useContext(MFAContext);
  const [, setState] = credential;
  const siteKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;

  const loadRecaptchaScript = () => {
    return new Promise((resolve) => {
      if (document.getElementById('recaptcha-script')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.id = 'recaptcha-script';
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  const handleSubmit = async (data) => {
    await loadRecaptchaScript();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action: 'submit' }).then((token) => {
        login(data,token);
      })
    })
  };

  const login = (data,token) => {
    if (token) {
      const payload = {
        email: data.email,
        password: data.password,
        ['g-recaptcha']: token, // eslint-disable-line
      };

      actions.login(payload, (data) => {
        const newData = {
          email: payload?.email,
          password: payload?.password,
          google_enabled: data?.data?.google_enabled,
          email_verified: data?.data?.email?.verified,
          email_masked: data?.data?.email?.masked,
          mobile_verified: data?.data?.mobile?.verified,
          mobile_masked: data?.data?.mobile?.masked,
          profile_type: data?.data?.profile_type,
          otp_via: data?.data?.google_enabled ? 'google' : '',
        };
        setState(newData);
        handleMFA(newData);
      });
    }
  };


  const handleMFA = (newData) => {
    createModal({
      content: (close) => (
        <ModalBody
          onClose={close}
          title="Multi-factor authentication"
          description=""
          md
        >
          <MFAModal onClose={close} state={newData} setState={setState} />
        </ModalBody>
      ),
    });
  };

  return (
    <LayoutImage>
      <FormWrapper
        className="w-full"
        defaultValues={INIT_FORM}
        validation={validationSchema}
        onSubmit={handleSubmit}
      >
        <div className="w-full flex justify-center md:justify-start">
          <img
            src={DeliveryBox}
            alt="DeliveryBox"
            title="DeliveryBox"
            className="w-24 md:w-32 mb-5"
          />
        </div>
        <h1 className="text-heading md:text-subtitle text-center md:text-left text-primary mb-4">
          Welcome, Business Partner!
        </h1>
        <Field
          component={Input}
          name="email"
          type="email"
          label="Email"
          wrapperClassName="mb-4"
        />
        <Field
          component={InputPassword}
          name="password"
          label="Password"
          wrapperClassName="mb-2"
          withIcon
        />
        <div className="text-right mb-10">
          <Link
            to="/forgot-password"
            className="text-body text-gray-800 hover:underline"
          >
            Forgot password?
          </Link>
        </div>
        <div className="flex justify-center w-full">
          <div className="w-4/5">
            <Button
              solid
              primary
              type="submit"
              isLoading={isLoading}
              className="w-full rounded-full mb-4"
            >
              LOGIN
            </Button>
          </div>
        </div>
        <div className="text-center">
          <Link to="/portal" className="text-subheading font-medium">
            Go back to Home Page
          </Link>
        </div>

      </FormWrapper>
    </LayoutImage>
  );
}

export default LoginFormSubscriber;