const parentUrl = 'MFA';

export const VERIFY_OTP = `${parentUrl}/VERIFY_OTP`;
export const VERIFY_EP = `/api/v1/verify/otp`;
export const AUTH_ENDPOINT = '/api/v1/initial/credential';
export const VERIFY_CREDENTIAL_EP = `/api/v1/verify/credential`;

export const LOGIN = 'AUTH/login';
export const AUTHENTICATION = 'AUTH/authentication';
export const TOKEN_ID = '_token';
export const PROFILE_DATA = 'AUTH/profile_data';
export const VERIFY_CREDENTIAL = 'AUTH/verify_credential';
export const UPDATE_PROFILE = 'AUTH/update_profile';

export const SUBSCRIBER_UPDATE_CONTACT = 'SUBSCRIBERS/update_contact';
export const VERIFY_OTP_SETTINGS = 'SUBSCRIBERS/verify_otp_setting';
export const VERIFY_APP_OTP = 'SUBSCRIBERS/verify_app_otp_success';


