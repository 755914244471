import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { useApiLoading } from 'react-reqq';
import {
  Button,
  Field,
  FormWrapper,
  InputPassword,
  ToastSuccess,
} from 'msys-ds';

import DocumentTitle from 'modules/common/components/DocumentTitle';

import LayoutImage from '../components/LayoutImage';
import { resetPassword } from '../actions';
import { RESET_PASSWORD } from '../constants';

const validationSchema = (yup) =>
  yup.object().shape({
    password: yup
      .string()
      .required('Required')
      .min(5, 'Must be at least 5 characters'),
    password_confirmation: yup
      .string()
      .required('Required')
      .oneOf([yup.ref('password')], "Password doesn't match"),
  });

const INIT_FORM = {
  password: '',
  password_confirmation: '',
};

function ResetPassword() {
  const history = useHistory();
  const isLoading = useApiLoading(RESET_PASSWORD, 'put');
  const location = useLocation();
  const search = location.search.substring(1);
  const parseData = JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    },
  );

  const handleSubmit = (data) => {
    const email = parseData?.email || '';
    const token = parseData.token || '';

    resetPassword({ ...data, email, token }, () => {
      history.push('/login/subscriber', { email });

      ToastSuccess('You have successfully changed your password');
    });
  };

  return (
    <>
      <DocumentTitle title="Reset Password" />
      <LayoutImage>
        <FormWrapper
          defaultValues={INIT_FORM}
          validation={validationSchema}
          onSubmit={handleSubmit}
          className="w-full"
        >
          <h1 className="text-subtitle text-primary mb-4">Reset Password</h1>
          <div className="mb-4">
            <Field
              component={InputPassword}
              name="password"
              label="New Password"
              placeholder="Enter New Password"
              required
              autoFocus
            />
          </div>
          <Field
            component={InputPassword}
            name="password_confirmation"
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            required
          />
          <div className="flex justify-center w-full mt-16">
            <div className="w-4/5">
              <Button
                solid
                primary
                lg
                type="submit"
                isLoading={isLoading}
                className="w-full rounded-full mb-2"
              >
                CONFIRM NEW PASSWORD
              </Button>
              <Button
                outline
                primary
                lg
                onClick={() => history.push('/login/subscriber')}
                isLoading={isLoading}
                className="w-full rounded-full"
              >
                CANCEL
              </Button>
            </div>
          </div>
        </FormWrapper>
      </LayoutImage>
    </>
  );
}

export default ResetPassword;
