export const AUTH_ENDPOINT = '/api/v1/initial/credential';
export const PROFILE_ENDPOINT = '/api/v1/profiles';
export const FORGOT_PASSWORD_ENDPOINT = '/api/v1/administrator/forgot-password';
export const RESET_PASSWORD_ENDPOINT = '/api/v1/administrator/reset-password';
export const CHANGE_PASSWORD_ENDPOINT = '/api/v1/profiles/change-password';
export const TOKEN_ID = '_token';

export const AUTHENTICATION = 'AUTH/authentication';
export const PROFILE_DATA = 'AUTH/profile_data';
export const LOGIN = 'AUTH/login';
export const PROFILE = 'AUTH/profile';
export const FORGOT_PASSWORD = 'AUTH/forgot_password';
export const RESET_PASSWORD = 'AUTH/reset_password';
export const CHANGE_PASSWORD = 'AUTH/change_password';
export const REGISTER = 'AUTH/register';
export const ACTIVATE_ACCOUNT = 'AUTH/activate_account';
export const ACL_PERMISSIONS = 'AUTH/acl_permissions';
