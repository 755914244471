import React, { Suspense, lazy } from 'react';

import { req } from 'react-reqq';
import { Link, Switch, Route, useHistory } from 'react-router-dom';

import { LOGOUT_ROUTES } from 'modules/common/datasets';
import { useAuth } from 'modules/auth/useAuth';
import { AUTHENTICATION } from 'modules/auth/constants';
import { logout } from 'modules/auth/actions';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
import PageLoader from 'modules/common/components/PageLoader';
import PublicRoutes from 'modules/partial/PublicRoutes';
import { InitiateGA } from 'modules/common/components/GoogleAnalytics';

const PrivateAdminRoutes = lazy(() => import('modules/partial/PrivateAdminRoutes'));
const PrivateSubscriberRoutes = lazy(() => import('modules/partial/PrivateSubscriberRoutes'));

const ROUTE_BY_TYPE = {
  profile_administrators: PrivateAdminRoutes,
  profile_subscribers: PrivateSubscriberRoutes,
};

function App() {
  InitiateGA();
  const history = useHistory();
  const { isAppReady, isAuthenticated, authType } = useAuth();

  function UnknownUserType() {
    return (
      <div className="h-screen w-screen flex bg-primary-500">
        <div className="m-auto p-8 rounded-lg border  text-center">
          <div className="mb-5">Your session has expired!</div>
          <Link className="btn light rounded-full lg" to="/" onClick={() => logout(() => history.push(LOGOUT_ROUTES[authType]))}>
            Go Back to Login Page
          </Link>
        </div>
      </div>
    );
  }

  const renderPrivate = React.useMemo(() => {
    const renderRoute = () => {
      const Home = ROUTE_BY_TYPE[authType] || UnknownUserType;
      return <Home />;
    };
    const handleLogout = () => {
      req.set(AUTHENTICATION, {
        isAuthenticated: true,
      });
      logout(() => {
        history.push(LOGOUT_ROUTES[authType]);
      });
    };
    return (
      <Switch>
        <Route path="/authenticate">
          <div className="h-screen w-screen flex bg-primary-500">
            <div className="m-auto p-8 rounded-lg border text-center flex flex-col">
              <div className="mb-5">An account is already logged in!</div>
              <Link className="btn light rounded-full lg mb-4" to="/">
                Keep me logged in!
              </Link>
              <Link
                className="btn light rounded-full lg"
                to="/"
                onClick={handleLogout}
              >
                Logout
              </Link>
            </div>
          </div>
        </Route>
        <Route>{renderRoute}</Route>
      </Switch>
    );
  }, [authType]); // eslint-disable-line

  if (!isAppReady) return null;
  return (
    <ErrorBoundary>
      {isAuthenticated ? (
        <Suspense fallback={<PageLoader />}>{renderPrivate}</Suspense>
      ) : (
        <PublicRoutes />
      )}
    </ErrorBoundary>
  );
};

export default App;
