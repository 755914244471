import React from 'react';

import PropTypes from 'prop-types';

import DeliveryBox from 'assets/images/deliverybox-logo.svg';

function Layout({ children }) {
  return (
    <main className="login-page w-screen flex items-center justify-center h-screen p-2 md:p-6">
      <div className="flex flex-col items-center bg-white p-10 md:p-12 rounded-lg w-full max-w-lg">
        <img
          src={DeliveryBox}
          alt="DeliveryBox"
          title="DeliveryBox"
          className="w-24 md:w-32 mb-5"
        />

        {children}
      </div>
    </main>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
