import React, { useEffect, useState } from 'react';
import DeliveryBox from 'assets/images/deliverybox-logo.svg';
import {
  useCreateModal,
  ModalBody,
} from 'msys-ds';
import { useHistory } from 'react-router-dom';

import AuthenticatorConfirmModal from 'modules/MFA/modals/AuthenticatorConfirmModal';
import OneTimePin from 'modules/MFA/components/OneTimePin';
import * as actions from '../actions';
import MFAModal from '../modals/MFAModal';
import Counter from '../components/Counter';

function OTP({state, setState}) {
  const history = useHistory();
  const createModal = useCreateModal('create-partner');
  const [secret, setSecret] = useState('');
  const [secondsRemaining, setSecondsRemaining] = useState(() => {
    // Get stored value from Local Storage or set initial value
    const storedSeconds = 300;
    return storedSeconds;
  });

  useEffect(() => {
    if (!state?.email) return history.push('/');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
        sessionStorage.setItem('countdown', secondsRemaining); // Update Local Storage
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function
  }, [secondsRemaining]); // Re-run on secondsRemaining change

  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;

  const handleMFA = () => {
    createModal({
      content: (close) => (
        <ModalBody
          onClose={close}
          title={
            <div className="font-semibold">Multi-factor authentication</div>
          }
          description="asdfs"
          md
        >
          <MFAModal onClose={close} state={state} setState={setState} />
        </ModalBody>
      ),
    });
  };

  const onSuccess = () => {
    createModal({
      content: (close) => (
        <ModalBody
          onClose={close}
          title={<div className="font-semibold">Setup Complete</div>}
          md
        >
          <AuthenticatorConfirmModal onClose={close} />
        </ModalBody>
      ),
    });
  };

  const resendOTP = () => {
    actions.verifyCredentials(
      {
        email: state?.email,
        password: state?.password,
        otp_via: state?.otp_via,
      },
      () => {
       sessionStorage.setItem('countdown', 300);
      },
    );
  };


  const handleSubmit = (secret) => {
    const payload = {
      email: state?.email,
      password: state?.password,
      otp_via: state?.otp_via,
      secret: secret,
    };

    actions.verifyOTP(payload, (data) => {
      sessionStorage.clear();
      if (data?.message)
        onSuccess();
    });
  };

  return (
    <main className="flex justify-center p-2 items-center">
      <div className="w-[780px] px-4 rounded-lg ">
        <div className="text-center">
          <div className="box-border mt-10 text-center">
            <img
              src={DeliveryBox}
              alt="DeliveryBox"
              title="DeliveryBox"
              className="h-20 mx-auto"
            />
          </div>
          <div>
            <h1 className="font-bold text-subtitle py-4 mt-5">Enter OTP</h1>
          </div>
          <div>
            <b className="max-w-md mx-auto  ">
              {['email', 'mobile'].includes(state.otp_via)
                ? `Code sent to ${
                    state.otp_via === 'email' ? state?.email_masked  : state?.mobile_masked
                  } via  ${state.otp_via}. It might take a few minutes to arrive.`
                : `Enter the 6-digit verification code generated by the authenticator app.`}
            </b>
          </div>
        </div>
        <div className="grid grid-cols-1 divide-y ">
          <div className="p-10">
            <div className="flex">
              <div className="w-full">
                <div className="flex mb-2 items-center">
                  <OneTimePin
                    value={secret}
                    onChange={(e) => {
                      setSecret(e);
                      if (e.length === 6) handleSubmit(e);
                    }}
                  />
                </div>
                <div className="relative flex group mx-[75px]">
                  <div className=" ">
                    {state?.otp_via === 'google' ? (
                      ''
                    ) : (
                      <div className="flex">
                        <div
                          onClick={secondsRemaining === 0 ? resendOTP : ''}
                          className={`${
                            secondsRemaining === 0
                              ? 'cursor-pointer border-b'
                              : 'text-gray-700'
                          }  `}
                        >
                          Resend Pin
                        </div>
                        <Counter minutes={minutes} seconds={seconds} />
                      </div>
                    )}
                  </div>
                  <div
                    className="flex-auto text-right cursor-pointer underline font-bold"
                    onClick={() => {
                      handleMFA();
                      if (state.profile_type === 'profile_administrators')
                        return history.push('/login/admin');
                      return history.push('/login');
                    }}
                  >
                    or try another way
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default OTP