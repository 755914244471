import React from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function DocumentTitle({ title }) {
  const appTitle = 'DeliveryBox';

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${appTitle}`}
      defaultTitle={appTitle}
    />
  );
}

DocumentTitle.defaultProps = {
  title: '',
};

DocumentTitle.propTypes = {
  title: PropTypes.string,
};

export default DocumentTitle;
