import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import {
  Button,
  FormWrapper,
} from 'msys-ds';



function AuthenticatorConfirmModal({ onClose }) {
  const history = useHistory();

  const handleSubmit = () => {
    history.push('/');
    onClose();
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
    >
      <div className="space-y-8 max-w-md mx-auto">
        <div>
          <div className="text-gray-800 text-xs">
            Multi-Factor Authentication is now enabled for your account.
          </div>
        </div>
        <div className="flex justify-center w-full">
          <Button
            solid
            primary
            type="submit"
            className="w-full rounded-sm mb-4"
          >
            Continue to Dashboard
          </Button>
        </div>
      </div>
    </FormWrapper>
  );
}

AuthenticatorConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AuthenticatorConfirmModal;
