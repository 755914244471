import { req } from 'react-reqq';
import Cookie from 'js-cookie';
import _ from 'lodash';
import { ToastSuccess, ToastError } from 'msys-ds';

import { formatSelectOption, flatIncludedToItem } from 'modules/common/utils';

import * as c from './constants';

export const login = (payload, callback) => {
  req.post({
    key: c.LOGIN,
    url: c.AUTH_ENDPOINT,
    payload,
    onSuccess: ({ response }) => {
      callback(response);
      sessionStorage.setItem('credential', payload);
      sessionStorage.setItem('MFA', response);
    },
  });
};

export const logout = (callback) => {
  Object.keys(Cookie.get()).forEach((cookieName) =>  {
    Cookie.remove(cookieName, {});
  });

  req.set(c.AUTHENTICATION, {
    isAuthenticated: false,
  });

  req.clearCache();
  if (callback) callback();
};

export const activateAccount = (payload, callback = () => {}) => {
  req.put({
    key: c.ACTIVATE_ACCOUNT,
    url: '/api/v1/user/activate',
    payload,
    onSuccess: () => {
      ToastSuccess('Your Email Address is now verified!');
      callback();
    },
    onError: () => {
      callback();
      ToastError('DeliveryBox can&apos;t activate your account');
    },
  });
};
export const getProfile = () => {
  req.get({
    key: c.PROFILE,
    url: c.PROFILE_ENDPOINT,
    cache: true,
    transform: (res) => {
      const { data, included } = res;
      return {
        data: flatIncludedToItem(data, included)
      };
    },
  });
};


export const getProfileOnUpdate = (callback) => {
  req.get({
    key: c.PROFILE,
    url: c.PROFILE_ENDPOINT,
    // cache: true,
    transform: (res) => {
      const { data, included } = res;
      return {
        data: flatIncludedToItem(data, included),
      };
    },
    onSuccess: ({ response }) => {
      const profileData = {
        ..._.get(response, 'data.attributes'),
        id: _.get(response, 'data.attributes.profile_id'),
      };
      
      Cookie.set('_profileData', profileData);

      callback(profileData);
    },
  });
};

export const forgotPassword = (payload, callback) => {
  req.put({
    key: c.FORGOT_PASSWORD,
    url: c.FORGOT_PASSWORD_ENDPOINT,
    payload,
    onSuccess: ({ response }) => {
      if (callback) callback(response);
    },
  });
};

export const resetPassword = (payload, callback) => {
  req.put({
    key: c.RESET_PASSWORD,
    url: c.RESET_PASSWORD_ENDPOINT,
    payload,
    onSuccess: () => {
      if (callback) callback();
    },
  });
};

export const changePassword = (payload, callback) => {
  req.put({
    key: c.CHANGE_PASSWORD,
    url: c.CHANGE_PASSWORD_ENDPOINT,
    payload,
    onSuccess: () => {
      if (callback) callback();
    },
  });
};
export const register = (payload, callback = () => {}) => {
  req.post({
    key: c.REGISTER,
    url: '/api/v1/user/register',
    payload,
    onSuccess: (res) => {
      const { message } = _.get(res, 'response');
      ToastSuccess(_.get(message, 'details'));
      callback();
    },
  });
};

export const GET_TAGGING_LIST = (params, callback = () => {}) => {
  req.list({
    key: 'GET_TAGGING_LIST',
    url: '/api/v1/common/taggings',
    params,
    onSuccess: (res) => {
      const { data } = _.get(res, 'response');
      callback(
        formatSelectOption(data, 'attributes.slug', 'attributes.name')
      );
    },
  });
};

export const getDeliveryTypeList = (params, callback = () => {}) => {
  req.list({
    key: 'GET_DELIVERY_TYPE_LIST',
    url: '/api/v1/common/taggings',
    params,
    onSuccess: (res) => {
      const { data } = _.get(res, 'response');
      callback(formatSelectOption(data, 'id', 'attributes.name'));
    },
  });
};


