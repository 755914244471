import { configureApi } from 'react-reqq';
import Cookie from 'js-cookie';
import { get, isEmpty } from 'lodash';
import { ToastError } from 'msys-ds';

import { TOKEN_ID } from 'modules/auth/constants';
import { logout } from 'modules/auth/actions';
import { jsUcFirst } from 'modules/common/utils';

import history from './history';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get(TOKEN_ID);

    if (!token) return {};

    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  cacheStorage: 'LOCALSTORAGE',
  onError: (res) => {
    // eslint-disable-next-line no-console
    const status = get(res, 'status', '');
    const isAuthorized = !isEmpty(Cookie.get('_token'));

    if (status === 401 && isAuthorized) {
      ToastError({ title: 'Session Expired', content: 'Please Log-In Again.' });
      history.push('/logout?delay=2000');
      return;
    }
    if (status === 409) {
      return;
    }
    const errorMsg = 'Something went wrong!';

    if (!res || res.status === 0) {
      ToastError('Unable to connect to server');
      return;
    }

    const response = get(res, 'response');
    const message = get(res, 'response.message');

    if (Array.isArray(response)) {
      ToastError(jsUcFirst(get(response, '0.detail', errorMsg)));
      return;
    }

    if (!message && typeof response === 'object') {
      const errors = get(response, 'errors');

      if (get(errors, '0.code') === 500) {
        ToastError('Server error');
        return;
      }

      // display the first error only
      const error = get(errors, '0.detail', errorMsg);

      if (
        error.toLowerCase().includes('token') &&
        (error.toLowerCase().includes('expired') ||
          error.toLowerCase().includes('invalid'))
      ) {
        logout();
        ToastError(error);
        return;
      }

      ToastError(jsUcFirst(error));
      return;
    }

    ToastError(jsUcFirst(message || errorMsg));
  },
});

export default store;
