export const LOGOUT_ROUTES = {
  profile_administrators: '/login/admin',
  profile_subscribers: '/login/subscriber',
};

export const monthList = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const FILTER_BY_DATE_OPTIONS = [
  { label: 'When transaction was made', value: 'created_at' },
  { label: 'When assigned a courier', value: 'assignment_at' },
  { label: 'When paid by customer', value: 'payment_at' },
];

export const paymentMethodOptions = [
  { label: 'Cash on Delivery', value: 'is_cod' },
  { label: 'E-Payment', value: 'is_epayment' },
];
