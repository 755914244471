import React from 'react';

import { useApiLoading } from 'react-reqq';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Button,
  Field,
  FormWrapper,
  Input,
  ToastError,
  ToastSuccess,
} from 'msys-ds';

import LayoutImage from '../components/LayoutImage';
import { forgotPassword } from '../actions';
import { FORGOT_PASSWORD } from '../constants';

const validationSchema = (yup) =>
  yup.object().shape({
    email: yup.string().required('Required').email('Invalid email'),
  });

const INIT_FORM = {
  email: '',
};

function ForgotPassword() {
  const history = useHistory();
  const isLoading = useApiLoading(FORGOT_PASSWORD, 'put');

  const siteKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;

  const loadRecaptchaScript = () => {
    return new Promise((resolve) => {
      if (document.getElementById('recaptcha-script')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.id = 'recaptcha-script';
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  const handleSubmit = async (data) => {
    await loadRecaptchaScript();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action: 'submit' }).then((token) => {
        submit(data, token);
      });
    });
  };



  // const handleOnChange = (value) => {
  //   setCaptcha(value);
  // };

  const submit = (data, token) => {
    if (_.isEmpty(token)) {
      ToastError('Please validate that you are not a robot');
      return;
    }
    const payload = {
      ...data,
      ['g-recaptcha']: token, // eslint-disable-line
    };
    forgotPassword(payload, (res) => {
      history.push('/login/subscriber');

      ToastSuccess(res?.success?.details ?? 'Check your email');
    });
  };

  return (
    <LayoutImage>
      <FormWrapper
        defaultValues={INIT_FORM}
        validation={validationSchema}
        onSubmit={handleSubmit}
        className="w-full"
      >
        <h1 className="text-subtitle text-primary mb-4">
          Forgot your password?
        </h1>
        <p className="text-gray-600 mb-4">
          Enter your email below, and we’ll send you
          <br />the reset link
        </p>

        <div className="mb-4">
          <Field
            component={Input}
            name="email"
            type="email"
            label="Email"
            placeholder="Enter Email"
            required
            autoFocus
          />
        </div>

        <div className="flex justify-center w-full mt-16">
          <div className="w-4/5">
            <Button
              solid
              primary
              lg
              type="submit"
              isLoading={isLoading}
              className="w-full rounded-full mb-2"
            >
              SEND RESET LINK
            </Button>
            <Button
              outline
              primary
              lg
              onClick={() => history.push('/login/subscriber')}
              isLoading={isLoading}
              className="w-full rounded-full"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </FormWrapper>
    </LayoutImage>
  );
}

export default ForgotPassword;
