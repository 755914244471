import { req } from 'react-reqq';
import Cookie from 'js-cookie';
import _ from 'lodash';
import * as c from './constants';
import { ToastSuccess, ToastError } from 'msys-ds';



export const login = (payload, callback) => {
  req.post({
    key: c.LOGIN,
    url: c.AUTH_ENDPOINT,
    payload,
    onSuccess: ({ response }) => {
      const newPayload = {
        ...payload,
        ...response,
        otp_via: response.google_enabled ? 'google' : '',
      };

      callback(newPayload);
    },
    onError: (data) => {
      console.error( data); // eslint-disable-line
    },
  });
};

export const verifyCredentials = (payload, callback) => {
  req.post({
    key: c.VERIFY_CREDENTIAL,
    url: c.VERIFY_CREDENTIAL_EP,
    payload,
    onSuccess: ({ response }) => {
      ToastSuccess(response?.message);
      callback(response);

    },
    onError: (data) => {
        ToastError(data?.message);
      if (+data?.status === 500) sessionStorage.clear();
    },
  });
};

export const verifyOTP = (payload, callback) => {
  req.post({
    key: c.VERIFY_OTP,
    url: c.VERIFY_EP,
    payload,
    onSuccess: ({ response }) => {
      const token = _.get(response, 'token.token');
      const type = _.get(response, 'token.data.attributes.profile_type');
      const profileData = {
        ..._.get(response, 'token.data.attributes'),
        id: _.get(response, 'token.data.attributes.profile_id'),
      };
      const aclRoles = _.get(response, 'token.included', []).find(
        (item) => item?.type === 'acl_roles',
      );
      const permissions = _.get(aclRoles, 'attributes.permissions', []).map(
        (item) => item?.code,
      );

      Cookie.set('_userType', type);
      Cookie.set('_profileData', profileData);
      Cookie.set('_permissions', permissions);
      Cookie.set(c.TOKEN_ID, token);

      if (!token) return;
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
        type,
      });
      req.set(c.PROFILE_DATA, profileData);
      if (callback) callback(response);
    },
    onError: () => {
      sessionStorage.clear();
    },
  });
};



export const updateContact = (payload, type, callback) => {
  req.post({
    key: c.SUBSCRIBER_UPDATE_CONTACT,
    url: `/api/v1/profiles/initiate/${type}`,
    payload,
    onSuccess: ({ response }) => {
      ToastSuccess(response?.message);
      if (callback) callback(response);
    },
  });
};

export const verifyOTPSetting = (payload, type, callback) => {
  req.put({
    key: c.VERIFY_OTP_SETTINGS,
    url: `/api/v1/profiles/verify/${type}`,
    payload,
    onSuccess: ({ response }) => {
      ToastSuccess(response?.message);
      if (callback) callback(response);
    },
  });
};

export const verifyAppOTP = (payload, type, callback) => {
  req.post({
    key: c.VERIFY_APP_OTP,
    url: `/api/v1/profiles/verify/${type}`,
    payload,
    onSuccess: ({ response }) => {
      ToastSuccess(response?.message);
      callback();
    },
  });
};

export const updateProfile = (payload, callback) => {
  req.put({
    key: c.UPDATE_PROFILE,
    url: '/api/v1/profiles',
    payload,
    onSuccess: ({ response }) => {
      ToastSuccess('Successfully updated');
      let profileData = {
        ..._.get(response, 'data.attributes'),
        id: _.get(response, 'data.attributes.profile_id'),
      };
      const profile = _.get(response, 'data.attributes.profile');

      profileData['profile'] = {
        ...profile,
        organization_contact_person: profile?.first_name
        ? `${profile?.first_name} ${profile?.last_name}`
        : '', 
      }

      // Cookie.set('_profileData', profileData);
      callback(profileData);
    },
  });
};
