import React, { useContext } from 'react';

import { useApiLoading } from 'react-reqq';
import {
  Button,
  Field,
  Input,
  InputPassword,
  FormWrapper,
  useCreateModal,
  ModalBody,
} from 'msys-ds';

import Layout from '../components/Layout';
import Version from '../components/Version';
import * as actions from '../../MFA/actions';
import * as c from '../../MFA/constants';
import MFAModal from '../../MFA/modals/MFAModal';
import { MFAContext } from './Login';

const INIT_FORM = {
  email: '',
  password: '',
};

const validationSchema = (yup) =>
  yup.object().shape({
    email: yup.string().required('Required').email('Invalid email'),
    password: yup.string().required('Required'),
});

function LoginFormAdmin() {
  const isLoading = useApiLoading(c.LOGIN, 'post');
  const createModal = useCreateModal('create-partner');
  const { credential } = useContext(MFAContext);
  const [, setState] = credential;


  const siteKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;

  const loadRecaptchaScript = () => {
    return new Promise((resolve) => {
      if (document.getElementById('recaptcha-script')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.id = 'recaptcha-script';
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  const handleSubmit = async (data) => {
    await loadRecaptchaScript();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action: 'submit' }).then((token) => {
        login(data, token);
      });
    });
  };

  const login = (data, token) => {
    if (token) {
      const payload = {
        email: data.email,
        password: data.password,
        ['g-recaptcha']: token, // eslint-disable-line
      };

      actions.login(payload, (data) => {
        const newData = {
          email: payload?.email,
          password: payload?.password,
          google_enabled: data?.data?.google_enabled,
          email_verified: data?.data?.email?.verified,
          email_masked: data?.data?.email?.masked,
          mobile_verified: data?.data?.mobile?.verified,
          mobile_masked: data?.data?.mobile?.masked,
          profile_type: data?.data?.profile_type,
          otp_via: data?.data?.google_enabled ? 'google' : '',
        };
        setState(newData);
        handleMFA(newData);
      });
    }
  };



  const handleMFA = (newData) => {
    createModal({
      content: (close) => (
        <ModalBody
          onClose={close}
          title="Multi-factor authentication"
          description="asdfs"
          md
        >
          <MFAModal onClose={close} state={newData} setState={setState} />
        </ModalBody>
      ),
    });
  };



  return (
    <Layout>
      <FormWrapper
        className="w-full"
        defaultValues={INIT_FORM}
        validation={validationSchema}
        onSubmit={handleSubmit}
      >
        <h1 className="text-heading md:text-subtitle text-center text-primary mb-4">
          Welcome, Admin!
        </h1>

        <Field
          component={Input}
          name="email"
          type="email"
          label="Email"
          wrapperClassName="mb-4"
        />
        <Field
          component={InputPassword}
          name="password"
          label="Password"
          wrapperClassName="mb-5"
          withIcon
        />
        <div className="flex justify-center w-full">
          <div className="w-2/3">
            <Button
              solid
              primary
              md
              type="submit"
              isLoading={isLoading}
              className="w-full mb-5 rounded-full"
            >
              Login
            </Button>
          </div>
        </div>
        <Version />
      </FormWrapper>
    </Layout>
  );
}

export default LoginFormAdmin;
