import React from 'react';

function Counter({minutes, seconds}) {


  return (
    <div className="ml-2">
      ({minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')})
    </div>
  );
}

export default Counter;
