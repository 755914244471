import React from 'react';

import PropTypes from 'prop-types';

import LaptopBG from 'assets/images/laptop-login-bg.png';

function LayoutImage({ children }) {
  return (
    <main className="login-page flex items-center justify-center h-screen p-2 overflow-auto">
      <div className="subscriber-container flex rounded-lg overflow-hidden">
        <div className="login-form flex items-center bg-white p-10 py-12 md:px-16 lg:px-20">
          {children}
        </div>
        <div className="hidden md:block image-section h-full overflow-hidden">
          <img
            src={LaptopBG}
            alt="Laptop"
            title="Laptop"
            className="h-full"
          />
        </div>
      </div>
    </main>
  );
}

LayoutImage.propTypes = {
  children: PropTypes.node.isRequired
};
export default LayoutImage;
