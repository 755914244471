import React, { useRef } from 'react';
import cn from 'classnames';
function OneTimePin({ onChange, value }) {
  const inputRef = useRef(null);

  return (
    <div
      className="relative group cursor-pointer mx-auto"
      onClick={() => {
        inputRef.current && inputRef.current.focus();
      }}
    >
      <div className="grid grid-cols-6 gap-2 ">
        <div
          className={cn(
            'border  rounded-md h-20 w-20 flex justify-center items-center font-medium text-primary-base text-display',
            {
              'border-gray-400 bg-gray-muted': !value[0],
            },
            {
              'bg-primary-muted': value[0],
            },
          )}
        >
          {value[0] || <span>&nbsp;</span>}
        </div>
        <div
          className={cn(
            'border rounded-md h-20 w-20 flex justify-center items-center font-medium text-primary-base text-display',
            {
              'border-gray-400 bg-gray-muted': !value[1],
            },
            {
              'bg-primary-muted': value[1],
            },
          )}
        >
          {value[1] || <span>&nbsp;</span>}
        </div>
        <div
          className={cn(
            'border  rounded-md h-20 w-20 flex justify-center items-center font-medium text-primary-base text-display',
            {
              'border-gray-400 bg-gray-muted': !value[2],
            },
            {
              'bg-primary-muted': value[2],
            },
          )}
        >
          {value[2] || <span>&nbsp;</span>}
        </div>
        <div
          className={cn(
            'border  rounded-md h-20 w-20 flex justify-center items-center font-medium text-primary-base text-display',
            {
              'border-gray-400 bg-gray-muted': !value[3],
            },
            {
              'bg-primary-muted': value[3],
            },
          )}
        >
          {value[3] || <span>&nbsp;</span>}
        </div>
        <div
          className={cn(
            'border  rounded-md h-20 w-20 flex justify-center items-center font-medium text-primary-base text-display',
            {
              'border-gray-400 bg-gray-muted': !value[4],
            },
            {
              'bg-primary-muted': value[4],
            },
          )}
        >
          {value[4] || <span>&nbsp;</span>}
        </div>
        <div
          className={cn(
            'border  rounded-md h-20 w-20 flex justify-center items-center font-medium text-primary-base text-display',
            {
              'border-gray-400 bg-gray-muted': !value[5],
            },
            {
              'bg-primary-muted': value[5],
            },
          )}
        >
          {value[5] || <span>&nbsp;</span>}
        </div>
        <input
          type="number"
          autoFocus
          className="opacity-0 absolute pointer-events-none"
          ref={inputRef}
          onChange={(e) => {
            if (e.target.value.length <= 6) onChange(e.target.value);
          }}
          value={value}
          title="One Time Pin"
        />
      </div>
    </div>
  );
}

export default OneTimePin;
